import { StyleSheet } from 'react-native'

import {
  Button,
  ControlledField,
  ExpoImage,
  Field,
  LightScreenWrapper,
  Spacer,
  Text,
} from '~components'
import { REGEX, VERIFY_EMAIL_IMAGE } from '~constants'
import { useCallback, useChangeEmailForm, useState, useTranslation } from '~hooks'
import { useMeQuery } from '~query-hooks'

export const ChangeEmailScreen = (): JSX.Element => {
  const { t } = useTranslation()

  const { userData } = useMeQuery()

  const [newEmail, setNewEmail] = useState<string>('')
  const [step, setStep] = useState<number>(0)

  const navigateStep0 = useCallback(() => {
    setStep(0)
  }, [])
  const navigateStep1 = useCallback((newEmail: string) => {
    setNewEmail(newEmail)
    setStep(1)
  }, [])

  const { control, errors, isSubmitting, submit } = useChangeEmailForm({ callback: navigateStep1 })

  const renderWriteEmailStep = useCallback(() => {
    return (
      <>
        <Text.Body>{t('change_email_address_screen.enter_new_email')}</Text.Body>
        <Spacer y="6" />
        <Field.Input isDisabled label={t('form.labels.current_email')} value={userData?.email} />
        <Spacer y="6" />
        <ControlledField.Input
          {...{ control, errors }}
          isRequired
          label={t('form.labels.new_email')}
          name="newEmail"
          onChangeText={setNewEmail}
          placeholder={t('form.placeholders.new_email')}
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.EMAIL,
              message: t('form.errors.invalid_email_format'),
            },
          }}
          value={newEmail}
        />
        <Spacer y="6" />
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          onPress={submit}
          testID="send_email"
          width="100%"
        >
          {t('change_email_address_screen.send_email')}
        </Button>
      </>
    )
  }, [t, userData?.email, control, errors, newEmail, isSubmitting, submit])

  const renderResendEmailStep = useCallback(() => {
    return (
      <>
        <ExpoImage
          alt="verify email icon"
          contentFit="contain"
          source={VERIFY_EMAIL_IMAGE}
          style={styles.verifyEmailImage}
        />
        <Spacer y="6" />
        <Text.Body>{t('change_email_address_screen.we_have_send', { email: newEmail })}</Text.Body>
        <Spacer y="6" />
        <Text.Body>{t('change_email_address_screen.email_doesnt_arrive')}</Text.Body>
        <Spacer y="6" />
        {/* FIXME: add resend verification mail action here */}
        <Button onPress={navigateStep0} testID="send_email" variant="Ghost" width="100%">
          {t('change_email_address_screen.resend_email')}
        </Button>
      </>
    )
  }, [navigateStep0, newEmail, t])

  return (
    <LightScreenWrapper
      iconName="message-line"
      title={t('change_email_address_screen.change_email')}
    >
      {step === 0 ? renderWriteEmailStep() : renderResendEmailStep()}
    </LightScreenWrapper>
  )
}

const styles = StyleSheet.create({
  verifyEmailImage: {
    alignSelf: 'center',
    height: 94,
    width: 94,
  },
})
