import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useChangeEmailMutation } from '~query-hooks'
import { ChangeEmailFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

const defaultValues: ChangeEmailFormValues = {
  newEmail: '',
}
type UseChangeEmailProps = {
  callback(newEmail: string): void
}
export const useChangeEmailForm = ({ callback }: UseChangeEmailProps) => {
  const { t } = useTranslation()

  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { changeEmailMutation } = useChangeEmailMutation()

  const {
    control,
    formState: { errors },
    setError: setFormError,
    clearErrors: clearFormErrors,
    setFocus,
    handleSubmit,
  } = useForm<ChangeEmailFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async ({ newEmail }: ChangeEmailFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await changeEmailMutation({ newEmail })
      callback(newEmail)
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    clearFormErrors,
    control,
    error,
    errors,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    submit: handleSubmit(onSubmit),
  }
}
