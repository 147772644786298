type Keys<T> = keyof T
export const keys = <T extends object>(object: T) => Object.keys(object) as (keyof T)[]

// Root_SCREENS
export const RootStackScreens = {
  AboutApp: 'AboutApp',
  AccountCreated: 'AccountCreated',
  Action: 'Action',
  ActivateAccount: 'ActivateAccount',
  ApplicationInfo: 'ApplicationInfo',
  ChangeEmail: 'ChangeEmail',
  ChangePassword: 'ChangePassword',
  CompanyDetails: 'CompanyDetails',
  CompanyRepresentative: 'CompanyRepresentative',
  ConfirmResetPassword: 'ConfirmResetPassword',
  ContactAddress: 'ContactAddress',
  EmailVerification: 'EmailVerification',
  MainTab: 'MainTab',
  NotFound: 'NotFound',
  PersonalData: 'PersonalData',
  Profile: 'Profile',
  QRScanner: 'QRScanner',
  RegistrationAddress: 'RegistrationAddress',
  ResetPassword: 'ResetPassword',
  ResetPasswordInfo: 'ResetPasswordInfo',
  SelectLanguage: 'SelectLanguage',
  Settings: 'Settings',
  SignIn: 'SignIn',
  SignUp: 'SignUp',
  TokenDetails: 'TokenDetails',
  Transactions: 'Transactions',
} as const

// BottomTabs_SCREENS
export const BottomTabsScreens = {
  WalletStack: 'WalletStack',
  HomeStack: 'HomeStack',
  ExamplesStack: 'ExamplesStack',
} as const

// WalletStack_SCREENS
export const WalletStackScreens = {
  Wallet: 'Wallet',
} as const

// ExamplesStack_SCREENS
export const ExamplesStackScreens = {
  Examples: 'Examples',
  Typography: 'Typography',
  Colors: 'Colors',
  Components: 'Components',
  DataFromBeScreen_EXAMPLE: 'DataFromBeScreen_EXAMPLE',
  TestForm: 'TestForm',
} as const

// HomeStack_SCREENS
export const HomeStackScreens = {
  Home: 'Home',
  Details: 'Details',
} as const

export type BottomTabsScreensKeys = Keys<typeof BottomTabsScreens>
