import { ImageSource } from 'expo-image'

/* eslint-disable @typescript-eslint/no-var-requires */
export const BACKGROUND_MOBILE =
  require('~assets/background/mobile/backgroundMobile.png') as ImageSource
export const BACKGROUND_WEB =
  require('~assets/background/web/authorize/backgroundWeb.png') as ImageSource
export const BACKGROUND_WEB_LIGHT =
  require('~assets/background/web/authorize/backgroundWebLight.png') as ImageSource
export const BACKGROUND_WEB_UNAUTHORIZE =
  require('~assets/background/web/unAuthorize/backgroundWebUnauthorize.png') as ImageSource
export const CONFIRM = require('~assets/svg/confirm.svg') as ImageSource
export const CONGRATULATIONS = require('~assets/svg/congratulations.svg') as ImageSource
export const EMPTY_STATE_ACTIVITIES = require('~assets/svg/emptyStateActivities.svg') as ImageSource
export const EMPTY_STATE_TOKENS = require('~assets/svg/emptyStateTokens.svg') as ImageSource
export const ERROR = require('~assets/svg/error.svg') as ImageSource
export const INFO = require('~assets/svg/info.svg') as ImageSource
export const LOGIN_IMAGE = require('~assets/svg/loginImage.svg') as ImageSource
export const LOGO = require('~assets/svg/wallutaLogo.svg') as ImageSource
export const LOGO_DARK = require('~assets/svg/wallutaLogoDark.svg') as ImageSource
export const VERIFY_EMAIL_IMAGE = require('~assets/svg/emailVerification.svg') as ImageSource

// KYC STATUS CHANGE - MODAL ICONS
export const KYC_FAILED_IMAGE = require('~assets/svg/kyc_status_change/failed.svg')
export const KYC_PENDING_IMAGE = require('~assets/svg/kyc_status_change/pending.svg')
export const KYC_SUCCESS_IMAGE = require('~assets/svg/kyc_status_change/success.svg')

// NETWORKS
export const BITCOIN_NETWORK = require('~assets/svg/networks/bitcoin.svg') as ImageSource
export const ETHEREUM_NETWORK = require('~assets/svg/networks/ethereum.svg') as ImageSource

export const NETWORKS = {
  Bitcoin: BITCOIN_NETWORK,
  Ethereum: ETHEREUM_NETWORK,
}

// TOKENS
export const AVALANCHE_TOKEN = require('~assets/svg/tokens/avax-logo.svg') as ImageSource
export const BINANCE_TOKEN = require('~assets/svg/tokens/bnb-logo.svg') as ImageSource
export const BITCOIN_TOKEN = require('~assets/svg/tokens/btc-logo.svg') as ImageSource
export const DAI_TOKEN = require('~assets/svg/tokens/dai-logo.svg') as ImageSource
export const ETHEREUM_TOKEN = require('~assets/svg/tokens/eth-logo.svg') as ImageSource
export const FANTOM_TOKEN = require('~assets/svg/tokens/ftm-logo.svg') as ImageSource
export const LITECOIN_TOKEN = require('~assets/svg/tokens/ltc-logo.svg') as ImageSource
export const MONDO_COMMUNITY_COIN_TOKEN =
  require('~assets/svg/tokens/mndcc-logo.svg') as ImageSource
export const MUNDUM_COIN_TOKEN = require('~assets/svg/tokens/mundum-logo.svg') as ImageSource
export const NANO_TOKEN = require('~assets/svg/tokens/xno-logo.svg') as ImageSource
export const NXUSD_TOKEN = require('~assets/svg/tokens/nxusd-logo.svg') as ImageSource
export const POLYGON_TOKEN = require('~assets/svg/tokens/matic-logo.svg') as ImageSource
export const STELLAR_LUMENS_TOKEN = require('~assets/svg/tokens/xlm-logo.svg') as ImageSource
export const TETHER_USDT_TOKEN = require('~assets/svg/tokens/usdt-logo.svg') as ImageSource
export const TRON_TOKEN = require('~assets/svg/tokens/trx-logo.svg') as ImageSource
export const WAVES_TOKEN = require('~assets/svg/tokens/waves-logo.svg') as ImageSource
export const WIREX_TOKEN = require('~assets/svg/tokens/wxt-logo.svg') as ImageSource
export const XRP_TOKEN = require('~assets/svg/tokens/xrp-logo.svg') as ImageSource
export const PLACEHOLDER_TOKEN = require('~assets/svg/tokens/placeholder.svg') as ImageSource

export const TOKENS = {
  AVAX: AVALANCHE_TOKEN,
  BNB: BINANCE_TOKEN,
  BTC: BITCOIN_TOKEN,
  DAI: DAI_TOKEN,
  ETH: ETHEREUM_TOKEN,
  FTM: FANTOM_TOKEN,
  LTC: LITECOIN_TOKEN,
  MNDCC: MONDO_COMMUNITY_COIN_TOKEN,
  MUNDUM: MUNDUM_COIN_TOKEN,
  XNO: NANO_TOKEN,
  NXUSD: NXUSD_TOKEN,
  MATIC: POLYGON_TOKEN,
  XLM: STELLAR_LUMENS_TOKEN,
  USDT: TETHER_USDT_TOKEN,
  TRX: TRON_TOKEN,
  WAVES: WAVES_TOKEN,
  WXT: WIREX_TOKEN,
  XRP: XRP_TOKEN,
}
