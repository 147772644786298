import { useMemo } from 'react'

import { LightScreenWrapper, SettingSection, SettingsItemType } from '~components'
import { ACCOUNT_TYPE, BADGE_TYPE, KYC_STATUS } from '~enums'
import { useCallback, useTranslation } from '~hooks'
import {
  useCreateVideoVerificationMutation,
  useGetVideoVerificationStatusQuery,
  useMeQuery,
} from '~query-hooks'
import { getToast, noop, openUrlInNewTab } from '~utils'

const { showWarningToast } = getToast()

export const ProfileScreen = ({ navigation }: ProfileScreenProps): JSX.Element => {
  const { navigate } = navigation
  const { t } = useTranslation()

  const { userData, isLoadingUserData } = useMeQuery()
  const { createVideoVerificationMutation } = useCreateVideoVerificationMutation()
  const { videoVerificationStatus } = useGetVideoVerificationStatusQuery({ id: userData?.identId })

  const showPersonalDataMissingInfo = useMemo(
    () => !userData?.checkAllUserRequiredData?.personalData,
    [userData?.checkAllUserRequiredData?.personalData]
  )
  const showRegistrationAddressMissingInfo = useMemo(
    () => !userData?.checkAllUserRequiredData?.registrationAddress,
    [userData?.checkAllUserRequiredData?.registrationAddress]
  )

  const showContactAddressMissingInfo = useMemo(
    () => !userData?.checkAllUserRequiredData?.contactAddress,
    [userData?.checkAllUserRequiredData?.contactAddress]
  )

  const showCompanyDetailsInfo = useMemo(
    () => !userData?.checkAllUserRequiredData?.companyDetails,
    [userData?.checkAllUserRequiredData?.companyDetails]
  )

  const showCompanyRepresentativeMissingInfo = useMemo(
    () => !userData?.checkAllUserRequiredData?.companyRepresentative,
    [userData?.checkAllUserRequiredData?.companyRepresentative]
  )

  const showMissingKYCDataInfo = useMemo(() => {
    const missingPrivateFormsData =
      showPersonalDataMissingInfo ||
      showRegistrationAddressMissingInfo ||
      showContactAddressMissingInfo

    if (userData?.accountType) {
      return missingPrivateFormsData
    } else
      return (
        missingPrivateFormsData || showCompanyDetailsInfo || showCompanyRepresentativeMissingInfo
      )
  }, [
    showCompanyDetailsInfo,
    showCompanyRepresentativeMissingInfo,
    showContactAddressMissingInfo,
    showPersonalDataMissingInfo,
    showRegistrationAddressMissingInfo,
    userData?.accountType,
  ])

  const startVideoVerification = useCallback(async () => {
    try {
      const response = await createVideoVerificationMutation()
      if ('link_to_video_ident' in response) {
        openUrlInNewTab({ url: response.link_to_video_ident })
      } else {
        showWarningToast(response.message)
      }
    } catch (e) {
      console.log('Can not generate KYC link', e)
    }
  }, [createVideoVerificationMutation])

  const handleKycPress = useCallback(
    () =>
      showMissingKYCDataInfo
        ? showWarningToast(t('toast.warning.complete_profile_forms_to_continue_kyc'))
        : startVideoVerification(),
    [showMissingKYCDataInfo, startVideoVerification, t]
  )

  const navigateToChangeEmail = useCallback(() => navigate('ChangeEmail'), [navigate])
  const navigateToChangePassword = useCallback(() => navigate('ChangePassword'), [navigate])
  const navigateToPersonalData = useCallback(() => navigate('PersonalData'), [navigate])
  const navigateToContactAddress = useCallback(() => navigate('ContactAddress'), [navigate])
  const navigateToCompanyDetails = useCallback(() => navigate('CompanyDetails'), [navigate])
  const navigateToCompanyRepresentative = useCallback(
    () => navigate('CompanyRepresentative'),
    [navigate]
  )
  const navigateToRegistrationAddress = useCallback(
    () => navigate('RegistrationAddress'),
    [navigate]
  )

  const PersonalSettings = useMemo(
    (): SettingsItemType[] => [
      {
        onPress: navigateToPersonalData,
        title: t('profile_screen.personal_data'),
        badgeTitle: showPersonalDataMissingInfo && t('profile_screen.missing_info'),
      },
      {
        onPress: navigateToRegistrationAddress,
        title: t('profile_screen.registration_address'),
        badgeTitle: showRegistrationAddressMissingInfo && t('profile_screen.missing_info'),
      },
      {
        onPress: navigateToContactAddress,
        title: t('profile_screen.contact_address'),
        badgeTitle: showContactAddressMissingInfo && t('profile_screen.missing_info'),
      },
    ],
    [
      navigateToContactAddress,
      navigateToPersonalData,
      navigateToRegistrationAddress,
      showContactAddressMissingInfo,
      showPersonalDataMissingInfo,
      showRegistrationAddressMissingInfo,
      t,
    ]
  )
  const CompanySettings = useMemo(
    (): SettingsItemType[] => [
      {
        onPress: navigateToCompanyDetails,
        title: t('profile_screen.company_details'),
        badgeTitle: showCompanyDetailsInfo && t('profile_screen.missing_info'),
      },
      {
        onPress: navigateToCompanyRepresentative,
        title: t('profile_screen.company_representative'),
        badgeTitle: showCompanyRepresentativeMissingInfo && t('profile_screen.missing_info'),
      },
    ],
    [
      navigateToCompanyDetails,
      navigateToCompanyRepresentative,
      showCompanyDetailsInfo,
      showCompanyRepresentativeMissingInfo,
      t,
    ]
  )
  const AccountSettings = useMemo(
    (): SettingsItemType[] => [
      {
        isDisabled: true,
        label: t('common.soon'),
        onPress: navigateToChangeEmail,
        title: t('profile_screen.change_email'),
      },

      { onPress: navigateToChangePassword, title: t('profile_screen.change_password') },
    ],
    [navigateToChangeEmail, navigateToChangePassword, t]
  )

  const { kycBadgeTitle, kycBadgeType } = useMemo(() => {
    switch (videoVerificationStatus) {
      case KYC_STATUS.SUCCESS:
        return { kycBadgeTitle: t('common.success'), kycBadgeType: BADGE_TYPE.SUCCESS }
      case KYC_STATUS.PENDING:
        return { kycBadgeTitle: t('common.pending'), kycBadgeType: BADGE_TYPE.INFO }
      case KYC_STATUS.FAILED:
        return { kycBadgeTitle: t('common.failed'), kycBadgeType: BADGE_TYPE.ERROR }
      default:
        return { kycBadgeTitle: t('profile_screen.missing_kyc'), kycBadgeType: BADGE_TYPE.WARNING }
    }
  }, [videoVerificationStatus, t])

  const SecuritySettings = useMemo(
    (): SettingsItemType[] => [
      {
        isDisabled:
          userData?.accountType === ACCOUNT_TYPE.BUSINESS ||
          videoVerificationStatus === KYC_STATUS.SUCCESS ||
          videoVerificationStatus === KYC_STATUS.PENDING,
        onPress: handleKycPress,
        label: userData?.accountType === ACCOUNT_TYPE.BUSINESS ? t('common.soon') : '',
        title: t('profile_screen.id_verification'),
        badgeType: userData?.accountType === ACCOUNT_TYPE.BUSINESS ? undefined : kycBadgeType,
        badgeTitle: userData?.accountType === ACCOUNT_TYPE.BUSINESS ? undefined : kycBadgeTitle,
      },
      {
        isDisabled: true,
        label: t('common.soon'),
        // TODO: Add proper action here
        onPress: noop,
        title: t('profile_screen.two_factor_auth'),
      },
    ],
    [handleKycPress, kycBadgeTitle, kycBadgeType, t, userData?.accountType, videoVerificationStatus]
  )

  return (
    <LightScreenWrapper
      iconName="profile-line"
      title={t('common.profile')}
      isLoading={isLoadingUserData}
    >
      <SettingSection settingsItems={PersonalSettings} />
      {userData?.accountType === ACCOUNT_TYPE.PRIVATE ? null : (
        <SettingSection settingsItems={CompanySettings} />
      )}
      <SettingSection settingsItems={AccountSettings} sectionTitle={t('profile_screen.account')} />
      <SettingSection
        settingsItems={SecuritySettings}
        sectionTitle={t('profile_screen.security')}
      />
    </LightScreenWrapper>
  )
}
