import { Image as ExpoImage } from 'expo-image'
import { useCallback } from 'react'
import { StyleSheet } from 'react-native'

import { Box } from '../../components/atoms/Box'
import { Icon } from '../../components/atoms/Icon'
import { Row } from '../../components/atoms/Row'
import { Spacer } from '../../components/atoms/Spacer'
import { Text } from '../../components/atoms/Text'
import { Pressable, PressableRef } from '../../components/atoms/Touchables/Pressable'
import { BottomTabsScreensKeys } from '../config/enums'

import {
  LOGO,
  LOGO_DARK,
  MOBILE_NAV_ICON_SIZE,
  TAB_DEFAULT_ICON,
  WEB_NAV_BAR_ICON_SIZE,
} from '~constants'
import {
  useNavigation,
  useNavigationState,
  useNavigationTheme,
  useTranslation,
  useWeb,
} from '~hooks'
import { bottomTabsScreensData } from '~navigation/config/tabs'
import { navigationRef } from '~utils'

const hitSlop = { bottom: 12, top: 12, left: 12, right: 12 }

// FIXME: need refactor -> extract common components, like Touchable with hover and pressed state !!

type WebNavBarTopProps = {
  isLightTopNav?: boolean
}

const WebNavBarTop = ({ isLightTopNav = false }: WebNavBarTopProps) => {
  const { t } = useTranslation()
  const { navigate, goBack, getState } = useNavigation()
  const { tabBarTheme } = useNavigationTheme()

  const { shouldApplyDesktopStyles } = useWeb()
  // This hook call is needed to rerender this component when navigation state will change
  // thanks to that function `getCurrentRoute` will have proper value
  useNavigationState((state) => state?.routes)

  const currentRouteName =
    navigationRef?.current?.getCurrentRoute()?.name ??
    getState()?.routes[getState()?.routes.length - 1]?.name ??
    ''

  const handleTabPress = useCallback(
    (stackName: BottomTabsScreensKeys) => {
      // Search for first screen that is in selected tab
      const screenToNavigate = bottomTabsScreensData?.find((stack) => stack.name === stackName)
        ?.screens?.[0].name as any // eslint-disable-line @typescript-eslint/no-explicit-any

      navigate(screenToNavigate)
    },
    [navigate]
  )

  const isActiveTab = useCallback(
    (screens: { name: string }[]) => {
      // search through screens that are in single bottom tab,
      // if one of screens from bottom tab is found then we should mark it as active
      return screens.some((screen) => screen.name === currentRouteName)
    },
    [currentRouteName]
  )

  const navigateToProfile = useCallback(() => navigate('Profile'), [navigate])
  const navigateToSettings = useCallback(() => navigate('Settings'), [navigate])

  return shouldApplyDesktopStyles ? (
    //  Return all the navigation bar (all icons and logo)
    <Box w="full" style={[{ ...tabBarTheme.topTabBarStyle }]}>
      {isLightTopNav ? (
        <Row alignItems="center" px={shouldApplyDesktopStyles ? 20 : 'auto'} py="2" width="100%">
          <Pressable flex={1} onPress={goBack}>
            <Icon color="brand_dark" name="arrow-go-back-line" size={MOBILE_NAV_ICON_SIZE} />
          </Pressable>
          <Box alignItems="center" flex={1} justifyContent="center">
            <ExpoImage alt="logo" contentFit="contain" source={LOGO_DARK} style={styles.logo} />
          </Box>
          <Box flex={1} />
        </Row>
      ) : (
        <Row alignItems="center" justifyContent="space-between" mx="auto" py="2" width="100%">
          <ExpoImage alt="app logo" contentFit="contain" source={LOGO} style={styles.logo} />
          <Row flex={1} ml={20}>
            {bottomTabsScreensData.map(({ name, icons, screens, options }) => {
              const focused = isActiveTab(screens)
              const iconToRender = (focused ? icons.active : icons.inactive) || TAB_DEFAULT_ICON
              const color = focused
                ? tabBarTheme.tabBarActiveTintColor
                : tabBarTheme.tabBarInactiveTintColor

              return (
                <Box key={name} mx="2">
                  <Pressable
                    // eslint-disable-next-line react/jsx-no-bind
                    onPress={() => handleTabPress(name)}
                  >
                    {({ isHovered, isPressed }: PressableRef) => (
                      <Row
                        // MOBILE
                        style={isPressed ? styles.iconContainer : undefined}
                        // DESKTOP
                        bg={isHovered ? 'hover' : 'transparent'}
                        alignItems="center"
                        // BOTH
                        p={3}
                        borderRadius={8}
                      >
                        <Icon
                          name={iconToRender}
                          size={WEB_NAV_BAR_ICON_SIZE}
                          color={color as ColorNames}
                        />
                        {options?.title ? (
                          <Text.Bold color={color as ColorNames} ml="1" selectable={false}>
                            {options?.title}
                          </Text.Bold>
                        ) : null}
                      </Row>
                    )}
                  </Pressable>
                </Box>
              )
            })}
          </Row>
          <Row alignItems="center">
            <Pressable onPress={navigateToProfile}>
              {({ isHovered, isPressed }: PressableRef) => (
                <Row
                  style={isPressed ? styles.iconContainer : undefined}
                  bg={isHovered ? 'hover' : 'transparent'}
                  alignItems="center"
                  p={3}
                  borderRadius={8}
                >
                  <Icon color="white" name="account-circle-line" size={WEB_NAV_BAR_ICON_SIZE} />
                  <Text.Bold color="white" ml="3" selectable={false}>
                    {t('common.profile')}
                  </Text.Bold>
                </Row>
              )}
            </Pressable>
            <Spacer x="8" />
            <Pressable alignItems="center" hitSlop={hitSlop} onPress={navigateToSettings}>
              {({ isHovered, isPressed }: PressableRef) => (
                <Row
                  style={isPressed ? styles.iconContainer : undefined}
                  bg={isHovered ? 'hover' : 'transparent'}
                  alignItems="center"
                  p={3}
                  borderRadius={8}
                >
                  <Icon color="white" name="settings-3-line" size={WEB_NAV_BAR_ICON_SIZE} />
                  <Text.Bold color="white" ml="3" selectable={false}>
                    {t('common.settings')}
                  </Text.Bold>
                </Row>
              )}
            </Pressable>
          </Row>
        </Row>
      )}
    </Box>
  ) : (
    //  Return profile icon, logo and setting icon
    <Box style={[{ ...tabBarTheme.topTabBarStyle }]}>
      <Row alignItems="center" justifyContent="space-between" mx="auto" py="2" width="100%">
        {isLightTopNav ? (
          <>
            <Pressable flex={1} onPress={goBack}>
              <Icon color="brand_dark" name="arrow-go-back-line" size={MOBILE_NAV_ICON_SIZE} />
            </Pressable>
            <Box alignItems="center" flex={1} justifyContent="center">
              <ExpoImage
                alt="logo RAZ DWA"
                contentFit="contain"
                source={LOGO_DARK}
                style={styles.logo}
              />
            </Box>
            <Box flex={1} />
          </>
        ) : (
          <>
            <Pressable hitSlop={hitSlop} onPress={navigateToProfile}>
              <Icon color="white" name="account-circle-line" size={MOBILE_NAV_ICON_SIZE} />
            </Pressable>
            <ExpoImage alt="app logo" contentFit="contain" source={LOGO} style={styles.logo} />
            <Pressable hitSlop={hitSlop} onPress={navigateToSettings}>
              <Icon color="white" name="settings-3-line" size={MOBILE_NAV_ICON_SIZE} />
            </Pressable>
          </>
        )}
      </Row>
    </Box>
  )
}
const WebNavBarBottom = () => {
  const { navigate } = useNavigation()
  const { shouldApplyDesktopStyles } = useWeb()
  const { tabBarTheme } = useNavigationTheme()
  // This hook call is needed to rerender this component when navigation state will change
  // thanks to that function `getCurrentRoute` will have proper value
  useNavigationState((state) => state?.routes)

  // FIXME: can't get current route name after refresh, or enter the screen
  const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name ?? ''
  // fix issue, when enter web
  // bottomTabsScreensData?.[0]?.screens?.[0]?.name

  const handleTabPress = useCallback(
    (stackName: BottomTabsScreensKeys) => {
      // Search for first screen that is in selected tab
      const screenToNavigate = bottomTabsScreensData?.find((stack) => stack.name === stackName)
        ?.screens?.[0].name as any // eslint-disable-line @typescript-eslint/no-explicit-any

      navigate(screenToNavigate)
    },
    [navigate]
  )

  const isActiveTab = useCallback(
    (screens: { name: string }[]) => {
      // search through screens that are in single bottom tab,
      // if one of screens from bottom tab is found then we should mark it as active
      return screens.some((screen) => screen.name === currentRouteName)
    },
    [currentRouteName]
  )

  if (shouldApplyDesktopStyles) return null

  return (
    <Box style={tabBarTheme.bottomTabBarStyle}>
      <Row justifyContent={'space-evenly'}>
        {bottomTabsScreensData.map(({ name, icons, screens }) => {
          const focused = isActiveTab(screens)
          const iconToRender = (focused ? icons.active : icons.inactive) || TAB_DEFAULT_ICON
          const color = focused
            ? tabBarTheme.tabBarActiveTintColor
            : tabBarTheme.tabBarInactiveTintColor
          return (
            <Box key={name} mx="2" flexGrow={1}>
              <Pressable
                // eslint-disable-next-line react/jsx-no-bind
                onPress={() => handleTabPress(name)}
                hitSlop={hitSlop}
              >
                {({ isHovered, isPressed }: PressableRef) => (
                  <Box
                    alignItems="center"
                    bg={isHovered ? 'primary.100' : 'transparent'}
                    borderRadius={8}
                    py="2"
                    style={isPressed ? styles.iconContainer : undefined}
                  >
                    <Icon
                      name={iconToRender}
                      size={WEB_NAV_BAR_ICON_SIZE}
                      color={color as ColorNames}
                    />
                  </Box>
                )}
              </Pressable>
            </Box>
          )
        })}
      </Row>
    </Box>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    opacity: 0.8,
    transform: [{ scale: 0.9 }],
  },
  logo: { height: 42, width: 100 },
})

export { WebNavBarBottom, WebNavBarTop }
