import { useEffect } from '~hooks'

export const ActionScreen = (props: ActionScreenProps): JSX.Element => {
  const {
    navigation: { navigate },
    route: {
      params: { mode, oobCode },
    },
  } = props

  useEffect(() => {
    if (mode === 'verifyEmail') {
      navigate('AccountCreated', { oobCode })
    } else if (mode === 'resetPassword') {
      navigate('ConfirmResetPassword', { oobCode })
      // TODO: add action for 'changeEmail'
    } else navigate('NotFound')
  }, [mode, navigate, oobCode])

  return <></>
}
