import { ImageBackground as ExpoImageBackground } from 'expo-image'
import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { Box, Divider, Icon, Loader, Row, Text } from '../atoms'

import { MOBILE_NAV_ICON_SIZE, BACKGROUND_WEB_LIGHT, isWeb, theme } from '~constants'
import { useAuth, useWeb } from '~hooks'
import { WebNavBarTop } from '~navigation/webNavigator/WebNavBar'
import { IconNames } from '~types/icon'

type LightScreenWrapperProps = {
  children: React.ReactNode
  iconName?: IconNames
  isLoading?: boolean
  showDivider?: boolean
  stretchContent?: boolean
  title?: string
}

const KEYBOARD_OFFSET = 120

export const LightScreenWrapper = ({
  children,
  iconName,
  isLoading = false,
  showDivider = true,
  stretchContent = false,
  title,
}: LightScreenWrapperProps) => {
  const { shouldApplyWideScreenStyles } = useWeb()
  const { isSignedIn } = useAuth()

  return (
    <LinearGradient
      colors={[theme.light.colors.brand_bg, theme.light.colors.white]}
      end={{ x: 0, y: 1 }}
      style={styles.container}
    >
      <ExpoImageBackground
        alt="bg-image"
        contentFit="cover"
        source={shouldApplyWideScreenStyles ? BACKGROUND_WEB_LIGHT : null}
        style={StyleSheet.absoluteFillObject}
      >
        {isSignedIn && isWeb && <WebNavBarTop isLightTopNav />}
        <KeyboardAwareScrollView
          contentContainerStyle={styles.contentContainer}
          extraHeight={KEYBOARD_OFFSET}
          showsVerticalScrollIndicator={false}
        >
          <Box
            bg="white"
            borderRadius={8}
            width="100%"
            maxWidth={800}
            px={4}
            py={6}
            flex={stretchContent ? 1 : 0}
          >
            <Row alignItems="center">
              {iconName && <Icon color="primary.500" name={iconName} size={MOBILE_NAV_ICON_SIZE} />}
              {title && (
                <Text.H4 color="coolGray.800" ml={4}>
                  {title}
                </Text.H4>
              )}
            </Row>
            {showDivider && <Divider my="6" />}
            {isLoading ? <Loader type="bubbles" /> : children}
          </Box>
        </KeyboardAwareScrollView>
      </ExpoImageBackground>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: {
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 40,
    paddingHorizontal: 16,
    paddingTop: 24,
  },
})
